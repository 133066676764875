import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'components';

export default ({ data, pageContext }: any) => {
  const { page } = data;
  const { title, content } = page;
  return (
    <Layout pageTitle={title} description="Page" image="Logo.png">
      <div
        className={`${pageContext.slug}-content`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Layout>
  );
};

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
